import React from 'react';
import styled from 'styled-components';

import { useCountdown } from './Countdown';
import MintButton from './MintButton';

import '../Animation.css';

const StyledBox = styled.section`
margin-top: 1rem;
flex: 1 1 auto;
@media (max-width: 1024px) {
  margin-top: 0.5rem;
}
`

const StyledTimer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
`

const StyledDisplay = styled.ul`
display: flex;
gap: 2rem;
@media (max-width: 896px) {
  gap: 0.8rem;
  width: 250px;
}
`

const ClockItem = styled.li`
list-style: none;
width: 5rem;
height: auto;
@media (max-width: 896px) {
  width: 3.5rem;
}
`

const CountNumber = styled.span`
background: #8e8e8e;
color: #ffffff;
font-size: 2rem;
font-weight: 700;
font-family: 'Stick No Bills', sans-serif;
padding: 1rem;
display: inline-block;
width: 100%;
text-align: center;
line-height: 1;
border-radius: 4px 4px 0 0;
@media (max-width: 896px) {
  font-size: 1.2rem;
  padding: 0.8rem;
}
`

const CountText = styled.p`
background: #ffffff;
font-family: 'Stick No Bills', sans-serif;
font-size: 1.1rem;
color: #000000;
padding: 0.5rem 0.7rem;
display: inline-block;
width: 100%;
text-align: center;
line-height: 1;
border-radius: 0 0 4px 4px;
`
//text-shadow:  0 0 1rem lightblue;
const StyledText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', sans-serif;
text-align: "center";
font-size: 1.5rem;
margin: 1rem 0;
text-align: center;
@media (max-width: 896px) {
  font-size: 1.5rem;
  margin: 0.5rem;
}
`

const StyledJoinContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
flex-direction: column;
@media (max-width: 1024px) {
margin: 1rem auto 0.5rem;
}
`

const StyledJoinLink = styled.a`
padding: 0.6rem 1rem 0.6rem;
border-radius: 50px;
border: 1px solid rgba(255, 255, 255, 0.5);
background-color: #8e8e8e;
font-weight: bold;
color: #ffffff;
width: 8.5rem;
cursor: pointer;
font-family: 'Stick No Bills', sans-serif;
text-decoration: none;
display: inline-flex;
align-items: center;
justify-content: center;
`

const StyledJoinText = styled.p`
font-family: 'Stick No Bills', sans-serif;
text-align: center;
font-size: 1rem;
`

const StyledDesc = styled.p`
color: #ffffff;
font-family: 'Syne Mono', sans-serif;
font-size: 1rem;
text-align: center;
`

const StyledOpen = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
margin: 1rem;
@media (max-width: 1024px) {
font-size: 1.5rem;
margin: auto;
}
`


const Timer = ({ targetDate }) => {
  
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    if (days < -1){
      return <StyledBox>
        <StyledTimer>
            <StyledOpen>
              Sale Is Open!!
            </StyledOpen>
            <StyledText>1 Card cost 0.04 ETH<br/>Excluding gas fees.</StyledText>
            <MintButton/>
        </StyledTimer>
      </StyledBox> 
    } else {
    return <StyledBox>
      <StyledTimer>
        <StyledDisplay>
            <ClockItem>
                <CountNumber>{days+1}</CountNumber>
                <CountText>Days</CountText>
            </ClockItem>
            <ClockItem>
                <CountNumber>{hours+24}</CountNumber>
                <CountText>Hour</CountText>
            </ClockItem>
            <ClockItem>
                <CountNumber>{minutes+60}</CountNumber>
                <CountText>Min</CountText>
            </ClockItem>
            <ClockItem>
                <CountNumber>{seconds+60}</CountNumber>
                <CountText>Sec</CountText>
            </ClockItem>
        </StyledDisplay>
          <StyledText>Pre-Sale Is Open!!</StyledText>
          <StyledDesc>Only whitelisted users!</StyledDesc>
          <MintButton/>
      </StyledTimer>
    </StyledBox> 
  }} else {
    return <StyledBox>
        <StyledTimer>
            <StyledDisplay>
                <ClockItem>
                    <CountNumber>{days}</CountNumber>
                    <CountText>Days</CountText>
                </ClockItem>
                <ClockItem>
                    <CountNumber>{hours}</CountNumber>
                    <CountText>Hour</CountText>
                </ClockItem>
                <ClockItem>
                    <CountNumber>{minutes}</CountNumber>
                    <CountText>Min</CountText>
                </ClockItem>
                <ClockItem>
                    <CountNumber>{seconds}</CountNumber>
                    <CountText>Sec</CountText>
                </ClockItem>
            </StyledDisplay>
            <StyledText>Mint Is Coming Soon!!</StyledText>
            <StyledJoinContainer className='join-community'>
              <StyledJoinLink href={"https://discord.gg/6yWpgnEv"}>
                <StyledJoinText>Join Community</StyledJoinText>
              </StyledJoinLink>  
            </StyledJoinContainer>
           
        </StyledTimer>
    </StyledBox> 
  };   
};

export default Timer;

/*
.gap p {
  font-size: 1.1rem;
  font-family: var(--font-secondary);
  margin-bottom: 2rem;
  text-align: center;
}
*/
import React from 'react';
import styled from 'styled-components'

import Footer from "./Footer";

import BG from "../assets/img/BGROAD.png"
import BGMOBILE from "../assets/img/BGMobileRoad.png";


const RoadBlock = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
flex-direction: column;
scroll-snap-align: center;
background-image: url(${BG});
background-size: cover;
background-position-x: center;
@media (max-width: 1024px) {
  background-image: url(${BGMOBILE});
}
`

const TitleDiv = styled.div`
padding: 1rem 2rem;
width: 100%;
top: 0;
height: 10%;
transition:  0.32s ease-in-out;
background-color: #121212;
@media (max-width: 1024px) {
    padding: 1rem auto 1rem;
  }
`

const TitleText = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
`

const CardsDiv = styled.div`
display: flex;
width: 100%;
flex-direction: row;
justify-content: center;
margin: auto;
@media (max-width: 1024px) {
margin: auto;
  }
`

const CardBox1 = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 2vh 5vh;
  margin: 2vh auto;
  background: radial-gradient(#2a2a2a, #000000);
  border: 0.2rem solid goldenrod;
  border-radius: 1rem;
  box-shadow: 0 0 1rem goldenrod;
  @media (max-width: 1024px) {
  padding: 4vh 4vh;
  }
`

const TextDiv1 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 1vh 0.5vh;
@media (max-width: 1024px) {
margin: 1vh 1vh;
  }
`

const TextDivList = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 5vh auto;
@media (max-width: 1024px) {
margin: 1vh;
  }
`

const Title1 = styled.h1`
color: #ffffff;
font-family: 'Stick No Bills', monospace;
text-align: center;
font-size: 1.5rem;
`

const Text1 = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: start;
font-size: 1rem;
width: 30vh;
@media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`

const CardBox2 = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 5vh;
  margin: 2vh auto;
  background-color: black;
  border: 0.2rem solid goldenrod;
  border-radius: 1rem;
  box-shadow: 0 0 1rem black;
  @media (max-width: 1024px) {
  display: none;
  }
`

const TextDiv2 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 2vh 0.5vh;
`

const Title2 = styled.h1`
color: #a8a8a8;
font-family: 'Stick No Bills', monospace;
text-align: center;
font-size: 1.5rem;
`

const Text2 = styled.p`
color: #a8a8a8;
font-family: 'Syne Mono', monospace;
text-align: start;
font-size: 1rem;
width: 30vh;
@media (max-width: 720px) {
    font-size: 1.2rem;
  }
`

const Roadmap = () => {
    return <RoadBlock id='roadmap'>
        <TitleDiv>
            <TitleText>Roadmap</TitleText>
        </TitleDiv>
        <CardsDiv>
            <CardBox1>
                <TextDiv1>
                    <Title1>CHAPTER 1</Title1>
                    <TextDivList>
                        <Text1>- Demonstration of our Arts</Text1>
                        <Text1>- Community building</Text1>
                        <Text1>- Website opening</Text1>
                        <Text1>- SmartContract deployment</Text1>
                        <Text1>- Whitelist and Free Mint spots giveaways</Text1>
                        <Text1>- 70-80's pre-sale</Text1>
                        <Text1>- 70-80's open sale</Text1>
                    </TextDivList>
                </TextDiv1>
            </CardBox1>
            <CardBox2>
                <TextDiv2>
                    <Title2>CHAPTER 2</Title2>
                    <TextDiv2>
                        <Text2>- Presentation of the collection dedicated to the 90's</Text2>
                        <Text2>- 90's SmartContract deployment</Text2>
                        <Text2>- 90's pre-sale</Text2>
                        <Text2>- 90's open sale</Text2>
                        <Text2>- Giveaway for 80's holders</Text2>
                        <Text2>- Intagrate ERC-4337 in our Web3 APP</Text2>
                        <Text2>- PHYSICAL NFT concept promo</Text2>
                    </TextDiv2>
                </TextDiv2>
            </CardBox2>
            <CardBox2>
            <TextDiv2>
                <Title2>CHAPTER 3</Title2>
                    <TextDiv2>
                        <Text2>- Presentation of the collection dedicated to the 00's</Text2>
                        <Text2>- ERC-20 and DAO smart contract deployment</Text2>
                        <Text2>- 00's smart contract deployment</Text2>
                        <Text2>- ERC-20 tokens giveaway for 80's, 90's holders</Text2>
                        <Text2>- 00's pre-sale</Text2>
                        <Text2>- 00's open sale</Text2>
                        <Text2>- First PHYSICAL NFT drawing</Text2>
                    </TextDiv2>
                </TextDiv2>
            </CardBox2>
            <CardBox2>
            <TextDiv2>
                <Title2>CHAPTER 4</Title2>
                    <TextDiv2>
                        <Text2>- Presentation of the collection dedicated to the 10's</Text2>
                        <Text2>- 10's smart contract deployment</Text2>
                        <Text2>- giveaway for 80's, 90's, 00's holders</Text2>
                        <Text2>- 10's pre-sale</Text2>
                        <Text2>- 10's open sale</Text2>
                        <Text2>- PHYSICAL NFT drawing</Text2>
                        <Text2>- Present roadmap 2.0</Text2>
                    </TextDiv2>
                </TextDiv2>
            </CardBox2>
        </CardsDiv>
        <Footer/>
    </RoadBlock>
};

export default Roadmap;
import React from 'react';
import styled from 'styled-components'

import BG from "../assets/img/BGABOUT.png"
import Cards from '../assets/video/example.mp4';

import '../Animation.css';

const AboutBlock = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
flex-direction: column;
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
background-size: cover;
background-position-x: center;
scroll-snap-align: center;
`

const TitleDiv = styled.div`
padding: 1rem 2rem;
width: 100%;
top: 0;
height: 10%;
transition:  0.32s ease-in-out;
background-color: #121212;
@media (max-width: 1024px) {
    padding: 1rem auto 1rem;
  }
`

const TitleText = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
`

const CardBox = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
padding: 3vh 3vh;
margin: 3vh 5vh 2vh;
background: radial-gradient(#2a2a2a, #000000);
border: 0.3rem solid #8e8e8e;
border-radius: 1rem;
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
@media (max-width: 1024px) {
  display: none;
}
`

const MobileCard = styled.section`
display: none;
  @media (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3vh 2vh;
  margin: auto 4vh;
  background: radial-gradient(#2a2a2a, #000000);
  border: 0.2rem solid #8e8e8e;
  border-radius: 1rem;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`

const Stylediframe = styled.iframe`
border-radius: 12px; 
`

const StyledMobiframe = styled.iframe`
border-radius: 12px; 
`

const Spoty = () => {
    return <AboutBlock id='spoty' image={BG}>
        <TitleDiv>
            <TitleText>Playlist</TitleText>
        </TitleDiv>
        <CardBox>
            <Stylediframe 
          src="https://open.spotify.com/embed/playlist/0vlF31s6BXsBQ1Nq3FA2T2?utm_source=generator&theme=0" 
          width="1000" height="520" frameBorder="0" allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
            </Stylediframe>
        </CardBox>  
        <MobileCard>
            <StyledMobiframe
          src="https://open.spotify.com/embed/playlist/0vlF31s6BXsBQ1Nq3FA2T2?utm_source=generator&theme=0" 
          width="300" height="520" frameBorder="0" allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
            </StyledMobiframe>
        </MobileCard>
    </AboutBlock>
};

export default Spoty;
import React from 'react';
import styled from 'styled-components'

const StyledFooter = styled.footer`
background: #121212;
background-position: center center;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
bottom: 0;
width: 100%;
`

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 10rem 1rem 10rem;
  @media (max-width: 1024px) {
    padding: 1rem 1rem 1rem 1rem;
  }
`

const StyledDescription = styled.p`
  color: #ffffff;
  font-family: 'Syne Mono', sans-serif;
  text-align: "center";
  font-size: 1rem;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export const MintFooter = () => {
  return (
    <StyledFooter>
        <StyledBox>
            <StyledDescription>
            © Evolution Of Hip-Hop NFT Card Collection
            </StyledDescription>
        </StyledBox>
    </StyledFooter>
  )
}

export default MintFooter;

/*
            Please make sure you are connected to the right network (
            Etherium Mainnet ) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.<br/>
*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Mint from './Mint';
import NoMint from './NoMint';
import store from './redux/store';
import { Provider } from "react-redux";
import {createGlobalStyle} from 'styled-components';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const Global = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
`

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "mint",
    element: <Provider store={store}><Mint/></Provider>,
  },
  {
    path: "nomint",
    element: <Provider store={store}><NoMint/></Provider>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Global/>
    <RouterProvider router={router} />
  </React.StrictMode>
);

import React from "react";
import styled from "styled-components";
import MintNav from "./mint-components/MintNav";
import NoMintContainer from "./mint-components/NoMintContainer";
import MintFooter from "./mint-components/MintFooter";
import BG from "./assets/img/BG.png";
import BGMobile from "./assets/img/BGMobileMint.png"

const AppWrapper = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
justify-content: start;
flex-direction: column;
background: #2a2a2a;
background-image: url(${BG});
background-size: cover;
background-position-x: center;
background-image: url(${BG});
@media (max-width: 1024px) {
  background-image: url(${BGMobile});
}
`

function NoMint() {
  return (
    <AppWrapper>
      <MintNav/>
      <NoMintContainer/>
      <MintFooter/>
    </AppWrapper>
  );
}

export default NoMint;
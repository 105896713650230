import React from 'react';
import styled from 'styled-components'

import Logo from '../assets/img/logo.svg';
import BG from "../assets/img/BGLOGO.png";
import BGMOBILE from "../assets/img/BGMobileLogo.png";

import NavLinks from './NavLinks';

const StyledLogoContainer = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
justify-content: space-between;
scroll-snap-align: center;
flex-direction: column;
background-image: url(${BG});
background-size: cover;
background-position-x: center;
@media (max-width: 1024px) {
  background-image: url(${BGMOBILE});
  padding: 0;
  justify-content: center;
}
`

const StyledLogo = styled.img`
  width: 50%;
  transition: width 0.5s;
  transition: height 0.5s;
  filter: drop-shadow(0 0 0.5rem black);
  @media (max-width: 1024px) {
    margin: auto;
    width: 100%;
    filter: drop-shadow(0 0 100px black);
  }
`;

const MainLogo = () => {
    return <StyledLogoContainer>
      <NavLinks/>
        <StyledLogo src={Logo} alt="" />
    </StyledLogoContainer>
};

export default MainLogo;
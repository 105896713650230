import React from "react";
import styled from "styled-components";

import MainLogo from "./components/MainLogo";
import AboutProject from "./components/AboutProject";
import Roadmap from "./components/Roadmap";
import Spoty from "./components/Spoty";

const AppWrapper = styled.div`
width:100%;
height: 100vh;
scroll-snap-type: y mandatory;
scroll-behavior: smooth;
overflow-y: auto;
scrollbar-width: none;
background: #2a2a2a;
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
background-size: cover;
background-position-x: center;
&::-webkit-scrollbar{
  display: none;
}
@media (max-width: 1024px) {
}
`

function App() {
  return (
      <AppWrapper>
        <MainLogo/>
        <AboutProject/>
        <Spoty/>
        <Roadmap/>
      </AppWrapper>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

import Timer from './Timer';

const StyledMintContainer = styled.div`
display: flex;
width: 100%;
margin: 1rem auto 1rem;
align-items: center;
justify-content: center;
@media (max-width: 1024px) {
margin: 0;
}
`
/**  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 2rem 10rem 2rem 10rem;
  background: radial-gradient(#2a2a2a, #000000);
  border: 3px solid #8e8e8e;
  border-radius: 1rem;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  @media (max-width: 896px) {
    padding: 1rem 3rem 1rem 3rem;
  } */
// Create a Wrapper component that'll render a <section> tag with some styles  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
const StyledBox = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
padding: 2rem 9rem;
background: radial-gradient(#2a2a2a, #000000);
border: 0.3rem solid #8e8e8e;
border-radius: 1rem;
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
@media (max-width: 1024px) {
  display: none;
}
`

const MobileCard = styled.section`
display: none;
  @media (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3vh 2vh;
  margin: 5vh 2vh;
  background: radial-gradient(#2a2a2a, #000000);
  border: 0.2rem solid #8e8e8e;
  border-radius: 1rem;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`

//text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
const StyledTitle = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
margin-bottom: 0.5rem;
@media (max-width: 896px) {
  font-size: 1.5rem;
}
`

const StyledText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: center;
font-size: 1.5rem;
`

const StyledTotal = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: center;
font-size: 1.5rem;
`

const StyledEnd = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
margin: 1.5rem;
@media (max-width: 1024px) {
font-size: 1.5rem;
margin: 1rem;
}
`

const PageLinks = styled.a`
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
`

const PageText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', bold;
font-size: 1.5rem;
text-align: center;
&:hover {
  text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
  scale: 1.2;
  transition: 0.3s ease-in-out;
}
&:active {
  scale: 1;
  text-shadow: none;
  transition: 0.3s ease-in-out;
}
`

const MintContainer = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
  });
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);
    return <StyledMintContainer>
      <StyledBox>
        <StyledTitle>Welcome to<br/>Evolution of Hip-Hop<br/>NFT card colection!</StyledTitle>
        <StyledTotal>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</StyledTotal>
        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
          <>
            <StyledEnd>The Sale has Ended.</StyledEnd>
            <StyledText>You can still find<br/> {CONFIG.NFT_NAME} cards on</StyledText>
            <PageLinks href={"https://testnets.opensea.io"}><PageText>opensea.io</PageText></PageLinks>            
          </>
          ) : (
          <>
            <Timer />
          </>
        )}
      </StyledBox>
      <MobileCard>
        <StyledTitle>Welcome to<br/>Evolution of Hip-Hop<br/>NFT card colection!</StyledTitle>
          <StyledText>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</StyledText>
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <StyledEnd>The Sale has Ended.</StyledEnd>
              <StyledText>You can still find<br/> {CONFIG.NFT_NAME} cards on</StyledText>
              <PageLinks href={"https://testnets.opensea.io"}><PageText>opensea.io</PageText></PageLinks>            
            </>
            ) : (
            <>
              <Timer />
            </>
          )}
      </MobileCard>
    </StyledMintContainer>    
};

export default MintContainer;

/*                
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
                {data.totalSupply}
                Number(data.totalSupply)
*/
import React from 'react';
import styled from 'styled-components';

import { HashLink as Link } from 'react-router-hash-link';

import navIcon1 from '../assets/img/discord.svg';
import navIcon2 from '../assets/img/x.svg';
import navIcon3 from '../assets/img/opensea.svg';
import navIcon4 from '../assets/img/etherscan.svg';

import '../Animation.css';

const SiteHat = styled.div`
display: flex;
padding: 1.5rem 5rem 1.5rem 5rem;
width: 100%;
top: 0;
height: 10%;
background-color: transparent;
@media (max-width: 1024px) {
  padding: 0 2rem 0;
}
`

const HomeDiv = styled.div`
display: flex;
align-items: center;
justify-content: start;
@media (max-width: 1024px) {
  display: none;
}
`

const HomeLink = styled.a`
color: transparent;
display: flex;
align-items: center;
justify-content: center;
`

const HomeText = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
&:hover {
  text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
  scale: 1.2;
  transition: 0.3s ease-in-out;
}
&:active {
  scale: 1;
  text-shadow: none;
  transition: 0.3s ease-in-out;
}
`

const RightSide = styled.div`
display: flex;
align-items: center;
justify-content: start;
margin-left: auto;
@media (max-width: 1024px) {
  margin: auto;
  justify-content: space-between;
  width: 100%;
}
`

const PageLinksDiv = styled.div`
display: flex;
align-items: center;
justify-content: start;
@media (max-width: 1024px) {
  display: none;
}
`

const PageLinks = styled(Link)`
display: flex;
justify-content: center;
margin: 0 2rem 0;
text-decoration: none;
`

const PageText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', bold;
font-size: 1.5rem;
text-align: center;
justify-content: space-between;
margin-left: 2rem;
&:hover {
  text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
  scale: 1.2;
  transition: 0.3s ease-in-out;
}
&:active {
  scale: 1;
  text-shadow: none;
  transition: 0.3s ease-in-out;
}
`

const MintDiv = styled.div`
display: flex;
margin-left: 5rem;
margin-right: 2rem;
@media (max-width: 1024px) {
  margin-left: 0;
}
`

const MintButton = styled(Link)`
display: flex;
border: solid;
justify-content: center;
align-items: center;
padding: 0.6rem 1rem 0.6rem;
border-radius: 50px;
border: 1px solid rgba(255, 255, 255, 0.5);
background: rgba(217, 217, 217, 0.1);
font-weight: bold;
text-decoration: none;
color: #ffffff;
width: 6rem;
height: 2.5rem;
cursor: pointer;
font-family: 'Stick No Bills', sans-serif;
@media (max-width: 1024px) {
  margin: 0;
}
`

const MintText = styled.p`
font-family: 'Stick No Bills', sans-serif;
text-align: center;
font-size: 1rem;
`

const StyledSocialIcons = styled.div`
margin-left: 1rem;
display: flex;
@media (max-width: 720px) {
  margin-left: auto;
}
`

const StyledLink = styled.a`
width: 2.5rem;
height: 2.5rem;
background: rgba(217, 217, 217, 0.1);
display: inline-flex;
border-radius: 50%;
margin-right: 6px;
align-items: center;
justify-content: center;
line-height: 1;
border: 1px solid rgba(255, 255, 255, 0.5);
`

const StyledIcon = styled.img`
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
`

const NavLinks = () => {
    return <SiteHat>
        <HomeDiv>
          <HomeLink href="/">
            <HomeText>Home</HomeText>
          </HomeLink>
        </HomeDiv>      
        <RightSide>
          <PageLinksDiv>
            <PageLinks to='#about' smooth>
              <PageText>About Project</PageText>
            </PageLinks>
            <PageLinks to='#spoty' smooth>
              <PageText>Playlist</PageText>
            </PageLinks>
            <PageLinks to='#roadmap' smooth>
              <PageText>Roadmap</PageText>
            </PageLinks>
          </PageLinksDiv>
          <MintDiv className='mint-link'>
            <MintButton to='/nomint'>
              <MintText>MINT</MintText>
            </MintButton>
          </MintDiv>
          <StyledSocialIcons className="social-icon">
            <StyledLink href={"https://discord.gg/XbUyXyR2Wk"}><StyledIcon src={navIcon1} alt="" /> </StyledLink>
            <StyledLink href={"https://twitter.com/NFT_HipHopEvo"}><StyledIcon src={navIcon2} alt="" /> </StyledLink>
          </StyledSocialIcons>  
        </RightSide>
    </SiteHat>
};

export default NavLinks;

/**            
 *          <StyledLink href={""}><StyledIcon src={navIcon3} alt="" /> </StyledLink>
            <StyledLink href={""}><StyledIcon src={navIcon4} alt="" /> </StyledLink>
 * 
 * 
 * 
 * <PageLinks to='#arts' smooth>
              <PageText>Artworks</PageText>
            </PageLinks> */
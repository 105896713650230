import React from 'react';
import styled from 'styled-components';

import navIcon1 from '../assets/img/discord.svg';
import navIcon2 from '../assets/img/x.svg';
import navIcon3 from '../assets/img/opensea.svg';
import navIcon4 from '../assets/img/etherscan.svg';

import '../Animation.css';

const SiteHat = styled.div`
display: flex;
padding: 1rem 5rem 1rem 5rem;
width: 100%;
top: 0;
height: 10%;
background-color: transparent;
@media (max-width: 1024px) {
  padding: 1rem 1rem;
}
`

const StyledSpan = styled.span`
display: flex;
width: 100%;
justify-content: space-between;
`

const HomeDiv = styled.div`
display: flex;
align-items: center;
justify-content: start;
@media (max-width: 1024px) {
}
`

const HomeLink = styled.a`
color: transparent;
display: flex;
align-items: center;
justify-content: center;
`

const HomeText = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
&:hover {
  text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
  scale: 1.2;
  transition: 0.3s ease-in-out;
}
&:active {
  scale: 1;
  text-shadow: none;
  transition: 0.3s ease-in-out;
}
`

const StyledSocialIcons = styled.div`
display: flex;
@media (max-width: 720px) {
  margin-left: auto;
}
`

const StyledLink = styled.a`
width: 2.5rem;
height: 2.5rem;
background: rgba(217, 217, 217, 0.1);
display: inline-flex;
border-radius: 50%;
margin-right: 6px;
align-items: center;
justify-content: center;
line-height: 1;
border: 1px solid rgba(255, 255, 255, 0.5);
`

const StyledIcon = styled.img`
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;

`

const MintNav = () => {
    return <SiteHat>
        <StyledSpan>
        <HomeDiv>
          <HomeLink href="/">
            <HomeText>Home</HomeText>
          </HomeLink>
        </HomeDiv>  
              <StyledSocialIcons className="social-icon">
                <StyledLink href={"https://discord.gg/XbUyXyR2Wk"}><StyledIcon src={navIcon1} alt="" /> </StyledLink>
                <StyledLink href={"https://twitter.com/NFT_HH_EvoCards"}><StyledIcon src={navIcon2} alt="" /> </StyledLink>
              </StyledSocialIcons>
        </StyledSpan>
    </SiteHat>
};

export default MintNav;

/**             <StyledLink href={"https://testnets.opensea.io/collection/evolutionofhiphoptestgoerli"}><StyledIcon src={navIcon3} alt="" /> </StyledLink>
                <StyledLink href={"https://goerli.etherscan.io/address/0xDF903D5fF47b1c5793bE9b847C9B09fB2b62fa3F"}><StyledIcon src={navIcon4} alt="" /> </StyledLink>
                */
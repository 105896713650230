import React from 'react';
import styled from 'styled-components'

import BG from "../assets/img/BGABOUT.png"
import Cards from '../assets/video/example.mp4';

import '../Animation.css';

const AboutBlock = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
flex-direction: column;
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
background-size: cover;
background-position-x: center;
scroll-snap-align: center;
`

const TitleDiv = styled.div`
padding: 1rem 2rem;
width: 100%;
top: 0;
height: 10%;
transition:  0.32s ease-in-out;
background-color: #121212;
@media (max-width: 1024px) {
    padding: 1rem auto 1rem;
  }
`

const TitleText = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
`

const CardBox = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
padding: 3vh 7vh;
margin: 3vh 40vh 2vh;
background: radial-gradient(#2a2a2a, #000000);
border: 0.3rem solid #8e8e8e;
border-radius: 1rem;
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
@media (max-width: 1024px) {
  display: none;
}
`

const MobileCard = styled.section`
display: none;
  @media (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3vh 2vh;
  margin: auto 4vh;
  background: radial-gradient(#2a2a2a, #000000);
  border: 0.2rem solid #8e8e8e;
  border-radius: 1rem;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`

const TextDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 0.5rem auto;
`

const StyledText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: start;
font-size: 1.2rem;
text-indent: 5%;
margin-bottom: 2%;
`

const RarityCont = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`

const RarityMain = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: start;
font-size: 1.2rem;
text-indent: 5%;
margin-bottom: 2%;
@media (max-width: 1024px) {
font-size: 1rem;
text-indent: 5%;
margin: 2%;
}
`

const RarityText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', monospace;
text-align: start;
font-size: 1.2rem;
text-indent: 5%;
width: 45vh;
@media (max-width: 1024px) {
width: 100%;
font-size: 0.9rem;
text-indent: 5%;
  }
`

const ExampleDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-left: 10vh;
margin-right: 2vh;
@media (max-width: 1024px) {
  margin: auto;
  }
`

const StyledCards = styled.video`
box-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey;
border: 3px solid #8e8e8e;
background-color: #8e8e8e;
border-radius: 50%;
width: 33vh;
transition: width 0.5s;
@media (max-width: 1024px) {
  }
`;

const JoinContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 1%;
@media (max-width: 1024px) {
padding: 0.4rem;
  }
`

const JoinLink = styled.a`
padding: 0.6rem 1rem 0.6rem;
border-radius: 50px;
border: 1px solid rgba(255, 255, 255, 0.5);
background-color: rgba(217, 217, 217, 0.1);;
font-weight: bold;
color: #ffffff;
width: 8.5rem;
cursor: pointer;
font-family: 'Stick No Bills', sans-serif;
text-decoration: none;
display: inline-flex;
align-items: center;
justify-content: center;
&:hover{
  text-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey; text-shadow: 0 0 2rem lightblue;
  scale: 1.2;
  transition: 0.3s ease-in-out;
}
&:active {
  scale: 1;
  text-shadow: none;
  transition: 0.3s ease-in-out;
}
`

const JoinText = styled.p`
font-family: 'Stick No Bills', sans-serif;
text-align: center;
font-size: 1rem;
@media (max-width: 1024px) {
  font-size: 0.9rem;
  }
`

const StyledDesc = styled.p`
color: #a8a8a8;
font-family: 'Syne Mono', monospace;
text-align: center;
font-size: 0.8rem;
width: auto;
@media (max-width: 720px) {
    font-size: 0.6rem;
  }
`

const AboutProject = () => {
    return <AboutBlock id='about' image={BG}>
        <TitleDiv>
            <TitleText>About Project</TitleText>
        </TitleDiv>
        <CardBox>
          <TextDiv>
            <StyledText>
            The music industry is developing by leaps and bounds, someone likes it, someone doesn't.
            It's silly to deny that hip-hop culture has had a huge impact on what we call modern music.
            This project aims to remind everyone how hip-hop culture developed.
            </StyledText>
            <StyledText>
            Thus, we want to create a strong community for all representatives and fans of hip-hop culture. 
            This is only the first part of our collection, and it is dedicated to the 80th.
            </StyledText>
          </TextDiv>
          <RarityCont>
            <TextDiv>
              <RarityMain>
              The collection based on ERC-1155: Multi Token Standard and consists of 31 unique tokens.
              Each of them has its own rarity, which is determined by the number of multiplies.
              </RarityMain>  
              <RarityText>
              - Gold📀(20 items)
              </RarityText>
              <RarityText>
              - Platinum💿(15 items)
              </RarityText>
              <RarityText>
              - Legendary👑(10 items)
              </RarityText>
              <RarityText>
              - Diamond💍(5 items)
              </RarityText>
              <RarityText>
              - Ones to Watch🚀(1 item)
              </RarityText>
            </TextDiv>
            <ExampleDiv>
                <StyledCards src={Cards} autoPlay loop muted controls={false}  alt="">OG</StyledCards>
            </ExampleDiv>
          </RarityCont>
          <JoinContainer>
            <JoinLink href={"https://discord.gg/6yWpgnEv"}>
              <JoinText>Join Community</JoinText>
            </JoinLink>
          </JoinContainer>
          <StyledDesc>
            By purchasing an NFT card you become the owner of the opportunity to participate in the raffles of exclusive handmade cards, that made according to their virtual prototype!!!
            Also we've got a bunch of other goodies!!!
          </StyledDesc>
        </CardBox>
        <MobileCard>
          <ExampleDiv>
            <StyledCards src={Cards} autoPlay loop muted controls={false}  alt="">OG</StyledCards>        
          </ExampleDiv>
          <TextDiv>
              <RarityMain>
              The collection based on ERC-1155: Multi Token Standard and consists of 31 unique tokens.
              Each of them has its own rarity, which is determined by the number of multiplies.
              </RarityMain>  
              <RarityText>
              - Gold📀(20 copies)
              </RarityText>
              <RarityText>
              - Platinum💿(15 copies)
              </RarityText>
              <RarityText>
              - Legendary👑(10 copies)
              </RarityText>
              <RarityText>
              - Diamond💍(5 copies)
              </RarityText>
              <RarityText>
              - Ones to Watch🚀(1 copy)
              </RarityText>
          </TextDiv>
          <JoinContainer>
            <JoinLink href={""}>
              <JoinText>Join Community</JoinText>
            </JoinLink>
          </JoinContainer>
        </MobileCard>
    </AboutBlock>
};

export default AboutProject;
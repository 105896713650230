import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import CardsDemo from "../components/CardsDemo";

const StyledMintContainer = styled.div`
display: flex;
width: 100%;
margin: 0 auto 1rem;
align-items: center;
justify-content: center;
@media (max-width: 1024px) {
margin: 0;
}
`

const StyledBox = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
padding: 2rem 9rem 1rem;
background: radial-gradient(#2a2a2a, #000000);
border: 0.3rem solid #8e8e8e;
border-radius: 1rem;
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
@media (max-width: 1024px) {
  display: none;
}
`

const StyledTitle = styled.h1`
color: #ffffff;
font-family: 'Syne Mono', bold;
text-align: center;
font-size: 2rem;
margin-bottom: 0.5rem;
@media (max-width: 896px) {
  font-size: 1.5rem;
}
`

const StyledText = styled.p`
color: #ffffff;
font-family: 'Syne Mono', sans-serif;
text-align: "center";
font-size: 1.5rem;
margin: 1rem 0;
text-align: center;
@media (max-width: 896px) {
  font-size: 1.5rem;
  margin: 0.5rem;
}
`

const StyledJoinContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top: 1rem;
@media (max-width: 1024px) {
margin: 0 auto 0;
}
`

const StyledJoinLink = styled.a`
padding: 0.6rem 1rem 0.6rem;
border-radius: 50px;
border: 1px solid rgba(255, 255, 255, 0.5);
background-color: #8e8e8e;
font-weight: bold;
color: #ffffff;
width: 8.5rem;
cursor: pointer;
font-family: 'Stick No Bills', sans-serif;
text-decoration: none;
display: inline-flex;
align-items: center;
justify-content: center;
`

const StyledJoinText = styled.p`
font-family: 'Stick No Bills', sans-serif;
text-align: center;
font-size: 1rem;
`


const MobileCard = styled.section`
display: none;
  @media (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3vh 2vh;
  margin: 1vh 2vh;
  background: radial-gradient(#2a2a2a, #000000);
  border: 0.2rem solid #8e8e8e;
  border-radius: 1rem;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`

function NoMintContainer() {
    return (
      <StyledMintContainer>
        <StyledBox>
            <StyledTitle>Welcome to<br/>Evolution of Hip-Hop<br/>NFT card colection!</StyledTitle>
            <StyledText>Mint Is Coming Soon!!</StyledText>
            <CardsDemo/>
            <StyledJoinContainer className='join-community'>
              <StyledJoinLink href={"https://discord.gg/XbUyXyR2Wk"}>
                <StyledJoinText>Join Community</StyledJoinText>
              </StyledJoinLink>  
            </StyledJoinContainer>
        </StyledBox>
        <MobileCard>
          <StyledTitle>Welcome to<br/>Evolution of Hip-Hop<br/>NFT card colection!</StyledTitle>
              <StyledText>Mint Is Coming Soon!!</StyledText>
              <CardsDemo/>
              <StyledJoinContainer className='join-community'>
                <StyledJoinLink href={"https://discord.gg/XbUyXyR2Wk"}>
                  <StyledJoinText>Join Community</StyledJoinText>
                </StyledJoinLink>  
              </StyledJoinContainer>
        </MobileCard>
      </StyledMintContainer>
      
    );
  }
  
  export default NoMintContainer;
import React from 'react';
import styled from 'styled-components'

import Cards from '../assets/video/example.mp4';

const StyledCardsContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
padding: 2vh 0 2vh 0;
@media (max-width: 896px) {
    padding: 0 0 1vh 0;
  }
`

const StyledCards = styled.video`
box-shadow: 1px 1px 2px grey, 0 0 1em white, 0 0 0.2em grey;
border: 0.2rem solid #8e8e8e;
background-color: #8e8e8e;
border-radius: 50%;
width: 250px;
margin: 0 auto 0 auto;
transition: width 0.5s;
@media (max-width: 896px) {
    width: 250px;
    margin: 2vh;
    filter: drop-shadow(0 0 100px);
  }
`;

const CardsDemo = () => {
    return <StyledCardsContainer>
        <StyledCards src={Cards} autoPlay loop muted controls={false}  alt="">OG</StyledCards>
    </StyledCardsContainer>
};

export default CardsDemo;